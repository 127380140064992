import React from 'react';
import { Button } from '@hagerty/react-components';

type Button = {
  buttonLabel: string;
  buttonType: string;
  href: string;
};

type StackableCardProps = {
  buttons?: Button[];
  hasDivider?: boolean;
  headingText?: React.ReactNode;
  leadText?: React.ReactNode;
  list?: React.ReactNode[];
};

const StackableCard = ({
  buttons,
  hasDivider = true,
  headingText,
  leadText,
  list,
}: StackableCardProps): JSX.Element => {
  if (!headingText && !leadText && !buttons) {
    return null;
  }

  const href = buttons?.[0]?.href;
  const Tag = href ? 'a' : 'h3';

  return (
    <div className="card-stack">
      <div className="card-stack__content-wrapper">
        <div className="card-stack__content">
          {/* Card Header Text */}
          {headingText && (
            <Tag href={href} className="card-stack__heading text-heading_3">
              {headingText}
            </Tag>
          )}

          {/* Card Lead Text */}
          {leadText && <p className="text_2">{leadText}</p>}

          {/* Card List */}
          {list && (
            <ul className="icon-list icon-list_bullet">
              {list.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          )}

          {/* Card Button */}
          {buttons && buttons[0] && (
            <div className="card-stack__button-wrapper">
              <Button {...buttons[0]} />
            </div>
          )}
        </div>
      </div>
      {hasDivider && <div className="divider"></div>}
    </div>
  );
};

export { StackableCard };
