import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby-link';
import { useTreatments } from '@splitsoftware/splitio-react';
import { Billboard, Section, Button } from '@hagerty/react-components';

import { Split, Treatment } from 'src/constants/Split';
import { isReady } from 'src/components/SplitContext/isReady';
import { SiteDisclaimer } from 'src/components/Disclaimers';
import { StackableCard } from 'src/components/stackable-card';
import { MainFooter } from 'src/features/footer';

const meta = {
  title: 'Welcome to Hagerty Drivers Club',
  description:
    'There’s regular car life, then there’s Hagerty Drivers Club car life. By joining the Club, you’ve unlocked everything you need to take owning, driving and loving cars to the next level.',
};

const billboard = {
  category: 'Hagerty Drivers Club®',
  heading: 'Read. Watch. Live. Breathe. Cars.',
  lead: 'Hagerty Drivers Club keeps you fueled up on all things automotive. How?',
};

const cards = [
  {
    id: 'media-card-1',
    hasDivider: true,
    headingText: (
      <>
        <em>Hagerty Drivers Club</em> magazine
      </>
    ),
    leadText:
      'Enjoy six issues a year of our award-winning magazine, packed with interviews and articles by car celebrities like Jay Leno, Wayne Carini, Aaron Robinson and more. And that’s just the beginning. Stay up to date with market insights and trends, maintenance and restoration guidance, member car stories and so much more. Did we mention the magazine is exclusively available to Drivers Club members?',
  },
  {
    id: 'media-card-2',
    hasDivider: false,
    headingText: 'Curated content',
    leadText:
      'Tune in for exclusive digital content not available anywhere else. That means original automotive shows featuring Magnus Walker, Jason Cammisa, Chris Jacobs and more; insider interviews; race coverage; livestreams and podcasts.',
  },
];

const InsuranceMedia = () => {
  const splitIsReady = isReady();
  const treatments = useTreatments([Split.FeatureInsurancePages]);
  const hasInsurancePages = treatments[Split.FeatureInsurancePages].treatment === Treatment.On;

  useEffect(() => {
    if (splitIsReady && !hasInsurancePages) navigate('/');
  }, [splitIsReady]);

  return (
    <>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
      </Helmet>
      <main>
        <Billboard
          categoryText={billboard.category}
          mainHeadingText={<h1 className="billboard__heading text-display_1 no-padding">{billboard.heading}</h1>}
          leadText={billboard.lead}
          video={{
            videoID: 'B3DLBVVG0j8',
            videoPlatform: 'youtube',
          }}
        />
        <div className="container container_center">
          <div className="grid grid-wrap gutters_direct grid_align-center">
            <div className="col xs-size_1-of-1 max-width_content">
              <div className="body content">
                <Section>
                  {cards.map((card) => (
                    <StackableCard
                      key={card.id}
                      hasDivider={card.hasDivider}
                      headingText={card.headingText}
                      leadText={card.leadText}
                    />
                  ))}
                  <div className="inset-s" />
                  <Button
                    href={`${process.env.HAGERTY_ROOT_URL}/media/videos`}
                    iconRight={{
                      icon: 'ArrowRight12',
                    }}
                  >
                    Start watching
                  </Button>
                </Section>
              </div>
              <SiteDisclaimer />
            </div>
          </div>
        </div>
        <MainFooter />
      </main>
    </>
  );
};

export default InsuranceMedia;
